export default class Event {
    constructor(name) {
        this._name = name;
        this._subscribers = [];
    }

    get name() {
        return this._name;
    }

    on(subscriber) {
        if(this._subscribers.find(item => item === subscriber) !== undefined) {
            return;
        }

        this._subscribers.push(subscriber)
    }

    off(subscriber) {
        this._subscribers = this._subscribers.filter(item => item !== subscriber);
    }

    trigger(data) {
        this._subscribers.forEach(item => item(data));
    }
}
