import axios from 'axios';
const loading = {};
const loadResource = (url) => {
    if (url in loading)
        return loading[url];

    return loading[url] =
        axios
            .get(url);
};


export default loadResource;