import loadScript from './load-script';
import GlobalInstance from '../global-instance';

const loadScenarioScript = async ({url, integrity}) => {
  try {
    await loadScript(url, integrity);
  }
  catch(e) {
    throw new Error(`Cannot load scenario script: '${url}'`);
  }

  const scenarioFn = GlobalInstance[url];
  if (typeof scenarioFn !== 'function')
    throw new Error(`Cannot parse scenario script: '${url}'`);

  delete GlobalInstance[url];
  return scenarioFn;
};

export default loadScenarioScript;