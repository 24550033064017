export function hideNode(node) {
  if (node.style.display !== 'none') node.style.display = 'none';
}

export function showNode(node) {
  if (node.style.display === 'none') node.style.display = '';
}

export function appendNode(html, target = document.body, hide = true) {
  const dummy = document.createElement('div');
  dummy.innerHTML = html.trim();

  const node = dummy.firstChild;
  if (hide) {
    hideNode(node);
  }

  return target.appendChild(node);
}

export function removeNode(node) {
  if (node && node.parentNode) {
    node.parentNode.removeChild(node);
  }
}

export function isFocusable(element) {
  if (element.tabIndex > 0 || (element.tabIndex === 0 && element.getAttribute('tabIndex') !== null)) {
    return true;
  }

  if (element.disabled) {
    return false;
  }

  switch (element.nodeName) {
    case 'A':
      return !!element.href && element.rel !== 'ignore';
    case 'INPUT':
      return element.type !== 'hidden' && element.type !== 'file';
    case 'BUTTON':
    case 'SELECT':
    case 'TEXTAREA':
      return true;
    case 'IFRAME':
      return true;
    default:
      return false;
  }
}

export function attemptFocus(element) {
  if (!isFocusable(element)) {
    return false;
  }

  try {
    element.focus();
  } catch (e) {
    // eslint-disable-next-line no-empty
  }
  return document.activeElement === element;
}

export function getFirstFocusableDescendant(element) {
  for (let i = 0; i < element.childNodes.length; i++) {
    const child = element.childNodes[i];
    if (attemptFocus(child)) {
      return child;
    }
    const focusableDescendant = getFirstFocusableDescendant(child);
    if (focusableDescendant) {
      return focusableDescendant;
    }
  }
  return null;
}

export function getLastFocusableDescendant(element) {
  for (let i = element.childNodes.length - 1; i >= 0; i--) {
    const child = element.childNodes[i];
    if (attemptFocus(child)) {
      return child;
    }
    const focusableDescendant = getLastFocusableDescendant(child);
    if (focusableDescendant) {
      return focusableDescendant;
    }
  }
  return null;
}
