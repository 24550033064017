import loadProgram from './load-program';
import loadScenarioScript from './load-scenario-script';
import runScenario from './run-scenario';
import logError from './log-error';
import PluginConfig from './models/plugin-config';

const run = async (programUrl, plugins) => {
  const program = await loadProgram(programUrl);
  const pluginsConfig = plugins.map((x) => new PluginConfig(x));

  const {scenarios} = program;

  // load scenario scripts in async batch
  const scenarioScriptPromises = program.scenarios.map((s) => loadScenarioScript(s));

  // await and execute scenario scripts in order
  for (let i = 0; i < scenarios.length; i++) {
    try {
      await runScenario(program, scenarios[i], scenarioScriptPromises[i], pluginsConfig);
    } catch (e) {
      logError(e); // if a scenario failed, log error and let other ones continue
    }
  }
};

export default run;
