import axios from 'axios';
import Event from './event';
import loadResource from './load-resource';
import getSurveyUrlWithData from './survey-url-with-data';
import InviteView from './invite-view';
import ContainerView from './container-view';
import * as inviteEvents from './invite-events';
import * as containerEvents from './container-events';
import logError from './log-error';
import loadScript from './load-script';
import globalInstance from '../global-instance';
import wrapEvent from './wrap-event';

const findByKey = (items, keyFn, key) => {
  if (typeof key !== 'string') return;

  return items.find((x) => keyFn(x).toLowerCase() === key.toLowerCase());
};

export default class Api {
  constructor(apiConfig, programConfig, scenarioConfig, pluginsConfig) {
    this._apiConfig = apiConfig;
    this._programConfig = programConfig;
    this._scenarioConfig = scenarioConfig;
    this._pluginsConfig = pluginsConfig;
    this._programCounters = [];
    this._activePlugins = [];

    this._state = {
      invite: undefined,
      container: undefined,
      survey: undefined,
      data: undefined,
    };

    this._events = {
      showInvite: new Event('api:show-invite'),
      acceptInvite: new Event('api:accept-invite'),
      declineInvite: new Event('api:decline-invite'),
      closeInvite: new Event('api:close-invite'),

      showContainer: new Event('api:show-container'),
      completeSurvey: new Event('api:complete-survey'),
      closeContainer: new Event('api:close-container'),
    };
  }

  get events() {
    return this._events;
  }

  setInvite(inviteName, inviteOptions) {
    const {invites} = this._programConfig;
    const invite = findByKey(invites, (x) => x.name, inviteName);

    if (invite === undefined) throw new Error(`Cannot find invite '${inviteName}'`);

    this._setState({invite, inviteOptions});
    loadResource(invite.url);
  }

  setContainer(containerName, containerOptions) {
    const {containers} = this._programConfig;
    const container = findByKey(containers, (x) => x.name, containerName);

    if (container === undefined) throw new Error(`Cannot find container '${containerName}'`);

    this._setState({container, containerOptions});
    loadResource(container.url);
  }

  setSurvey(surveyId) {
    const {surveys} = this._programConfig;
    const survey = findByKey(surveys, (x) => x.id, surveyId);

    if (survey === undefined) throw new Error(`Cannot find survey '${surveyId}'`);

    this._setState({survey});
  }

  setSurveyData(data, shouldMerge) {
    if (shouldMerge) {
      data = {
        ...this._state.data,
        ...data,
      };
    }

    this._setState({data});
  }

  usePlugin(pluginName, ...args) {
    const plugin = findByKey(this._pluginsConfig, (x) => x.name, pluginName);
    if (plugin === undefined) throw new Error(`Cannot find plugin '${pluginName}'`);

    const {clientKey, url, integrity} = plugin;
    if (findByKey(this._activePlugins, (x) => x.clientKey, clientKey)) return;

    const loadingPromise = loadScript(url, integrity);
    this._activePlugins.push({clientKey, loadingPromise, args});
  }

  show() {
    this._showAsync().catch(logError);
  }

  async _showAsync() {
    for (const {clientKey, loadingPromise, args} of this._activePlugins) {
      await loadingPromise;
      const pluginFn = globalInstance[clientKey];
      if (typeof pluginFn !== 'function') throw new Error(`Cannot find plugin init function by key '${clientKey}'.`);
      pluginFn(...args);
    }

    this._showInvite() || this._showContainer();
  }

  async loadCountersAsync() {
    const {countersUrl} = this._programConfig;
    const {data} = await loadResource(countersUrl);
    this._programCounters = data;
  }

  async checkContactFrequencyRules(projectId, contactId) {
    const {cfrUrl, programKey} = this._programConfig;
    if (!cfrUrl) {
      logError('CFR URL cannot be empty');
      return {status: 'error'};
    }

    const url = `${cfrUrl}?projectId=${projectId}&contactId=${encodeURIComponent(contactId)}`;
    try {
      const response = await axios.get(url, {
        headers: {
          'X-ClientId': programKey,
        },
      });
      return {status: response.data.status};
    } catch (e) {
      logError(e);
      return {status: 'error'};
    }
  }

  getScenarioCounters(surveyId) {
    const {id: scenarioId} = this._scenarioConfig;
    const defaultCounters = {
      completesCurrentHour: 0,
      completesCurrentDay: 0,
      completesCurrentWeek: 0,
      completesTotal: 0,
    };
    const counters = this._programCounters.find((c) => c.scenarioId === scenarioId && c.surveyId === surveyId);
    if (counters === undefined) return defaultCounters;

    return {...defaultCounters, ...counters};
  }

  _setState(obj = {}) {
    this._state = {...this._state, ...obj};
  }

  _showInvite() {
    const {invite, inviteOptions} = this._state;
    if (invite === undefined) return;

    const view = new InviteView(invite, inviteOptions);

    const inviteEventArgs = {model: invite, view};

    view.showInviteEvent.on(() => this._onInviteEvent(inviteEvents.InvitePresented, inviteEventArgs));
    view.acceptInviteEvent.on(() => this._onInviteEvent(inviteEvents.InviteAccepted, inviteEventArgs));
    view.declineInviteEvent.on(() => this._onInviteEvent(inviteEvents.InviteDeclined, inviteEventArgs));
    view.closeInviteEvent.on(() => this._onInviteEvent(inviteEvents.InviteClosed, inviteEventArgs));
    view.render().catch(logError);

    return view;
  }

  _onInviteEvent(eventType, inviteEventArgs) {
    this._triggerPublicInviteEvent(eventType, inviteEventArgs);
    this._triggerApiInviteEvent(eventType, inviteEventArgs);

    if (eventType === inviteEvents.InviteAccepted) this._showContainer();
  }

  _shouldTriggerApiInviteEvent(eventType) {
    const configKey = `count${eventType}`;
    const {[configKey]: configValue} = this._apiConfig;
    return !!configValue;
  }

  _triggerApiInviteEvent(eventType, {model}) {
    if (!this._shouldTriggerApiInviteEvent(eventType)) return;

    const {eventsUrl, programKey} = this._programConfig;
    const {id: scenarioId} = this._scenarioConfig;

    const url = `${eventsUrl}&eventType=${eventType}&scenarioId=${scenarioId}&inviteId=${model.id}`;
    axios
      .get(url, {
        headers: {
          'X-ClientId': programKey,
        },
      })
      .catch(logError);
  }

  _triggerPublicInviteEvent(eventType, {model, view}) {
    const publicEvents = {
      [inviteEvents.InvitePresented]: this._events.showInvite,
      [inviteEvents.InviteAccepted]: this._events.acceptInvite,
      [inviteEvents.InviteDeclined]: this._events.declineInvite,
      [inviteEvents.InviteClosed]: this._events.closeInvite,
    };

    const closeInvite = (fireEvent) => {
      view.close(fireEvent);
    };

    const event = publicEvents[eventType];
    event.trigger({inviteName: model.name, closeInvite});
  }

  _showContainer() {
    const {container, containerOptions, survey, data} = this._state;
    if (container === undefined) return;

    const {programKey} = this._programConfig;
    const {id: scenarioId} = this._scenarioConfig;

    let surveyUrl;
    if (survey !== undefined) {
      surveyUrl = getSurveyUrlWithData(programKey, scenarioId, survey.url, data);
    }

    const view = new ContainerView(container, surveyUrl, containerOptions);

    const containerEventArgs = {model: container, view, survey, surveyUrl};

    view.showContainerEvent.on(() => this._onContainerEvent(containerEvents.ContainerPresented, containerEventArgs));
    view.closeContainerEvent.on(() => this._onContainerEvent(containerEvents.ContainerClosed, containerEventArgs));
    view.completeSurveyEvent.on(() => this._onContainerEvent(containerEvents.SurveyCompleted, containerEventArgs));
    view.render().catch(logError);

    return view;
  }

  _onContainerEvent(eventType, containerEventArgs) {
    this._triggerPublicContainerEvent(eventType, containerEventArgs);
  }

  _triggerPublicContainerEvent(eventType, {model, view, survey, surveyUrl}) {
    const publicEvents = {
      [containerEvents.ContainerPresented]: this._events.showContainer,
      [containerEvents.ContainerClosed]: this._events.closeContainer,
      [containerEvents.SurveyCompleted]: this._events.completeSurvey,
    };

    const closeContainer = (fireEvent) => {
      view.close(fireEvent);
    };

    const setContainerHidden = () => {
      view.setHidden();
    };

    const setContainerVisible = () => {
      view.setVisible();
    };

    const surveyMessageEvent = wrapEvent(view.surveyMessageEvent);
    const sendMessageToSurvey = (data) => {
      view.sendMessageToSurvey(data);
    };

    const event = publicEvents[eventType];
    const args = {
      containerName: model.name,
      surveyId: survey.id,
      surveyUrl,
      closeContainer,
      setContainerHidden,
      setContainerVisible,
      surveyMessageEvent,
      sendMessageToSurvey,
    };
    event.trigger(args);
  }
}
