export default class InviteConfig {
  constructor({id, name, url}) {
    this._id = id;
    this._name = name;
    this._url = url;
  }

  get id() {
    return this._id;
  }
  get name() {
    return this._name;
  }
  get url() {
    return this._url;
  }
}
