const getQuery = (programKey, scenarioId, data = {}) => {
    // make sure system params override custom ones and placed before them in query string
    const system = {'__digital_feedback_ctx': JSON.stringify({programKey, scenarioId})};

    const containsKey = (obj, key) => Object.keys(obj).find(x => x.toLowerCase() === key.toLowerCase()) !== undefined;

    const systemParams = Object.entries(system);
    const customParams = Object.entries(data)
        .filter(([key]) => !containsKey(system, key));

    const query = [...systemParams, ...customParams]
        .map(([key,value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&');


    return query;
};

const getSurveyUrlWithData = (programKey, scenarioId, url, data) => {
    if (typeof url !== 'string')
        return;

    const querySeparator = (url.indexOf('?') === -1 ? '?' : '&');

    const query = (typeof data === 'object')
        ? getQuery(programKey, scenarioId, data)
        : getQuery(programKey, scenarioId);

    return `${url}${querySeparator}${query}`;
};

export default getSurveyUrlWithData;