export default class PluginConfig {
  constructor({name, version, link: {url, integrity}}) {
    this._name = name;
    this._version = version;
    this._url = url;
    this._integrity = integrity;
  }

  get name() {
    return this._name;
  }

  get version() {
    return this._version;
  }

  get url() {
    return this._url;
  }

  get integrity() {
    return this._integrity;
  }

  get clientKey() {
    return `${this.name}:${this.version}`;
  }
}
