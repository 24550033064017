import loadResource from './load-resource';
import ProgramConfig from './models/program-config';

const loadProgram = async (programUrl) => {
  try {
    const {data} = await loadResource(programUrl);
    return new ProgramConfig(data);
  } catch (e) {
    throw new Error(`Cannot load program: ${programUrl}`);
  }
};

export default loadProgram;
