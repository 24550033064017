import Event from './event';
import * as HtmlUtils from './html-utils';
import loadResource from './load-resource';

export default class InviteView {
  constructor(invite, options = {}) {
    this._invite = invite;
    this._options = options;

    this._wrapper = null;
    this._node = null;

    this._showInviteEvent = new Event('invite:show');
    this._acceptInviteEvent = new Event('invite:accept');
    this._declineInviteEvent = new Event('invite:decline');
    this._closeInviteEvent = new Event('invite:close');
  }

  get showInviteEvent() {
    return this._showInviteEvent;
  }

  get acceptInviteEvent() {
    return this._acceptInviteEvent;
  }

  get declineInviteEvent() {
    return this._declineInviteEvent;
  }

  get closeInviteEvent() {
    return this._closeInviteEvent;
  }

  async render() {
    this.close();

    const {url: inviteUrl} = this._invite;
    const {
      data: {html, css},
    } = await loadResource(inviteUrl);

    this._renderContents(html, css);
    this._attachEvents();
    this._show();
  }

  close(fireEvent = false) {
    if (this._node) {
      HtmlUtils.removeNode(this._node);
      this._node = null;

      HtmlUtils.removeNode(this._wrapper);
      this._wrapper = null;

      if (fireEvent) this._closeInviteEvent.trigger();
    }
  }

  _resolveTarget() {
    const {target} = this._options;

    if (!target) return document.body;

    if (typeof target === 'string') return document.querySelector(target);

    return target;
  }

  _renderContents(html, css) {
    const target = this._resolveTarget();

    // need to add visible wrapper into DOM before rendering invite node to make accessibility work
    const wrapperHtml = '<div aria-live="polite"></div>';
    this._wrapper = HtmlUtils.appendNode(wrapperHtml, target, false);

    this._node = HtmlUtils.appendNode(html, this._wrapper);

    const styles = `<style>${css}</style>`;
    HtmlUtils.appendNode(styles, this._node);
  }

  _attachEvents() {
    const acceptBtn = this._node.querySelectorAll('[data-invite-accept]');
    acceptBtn.forEach((btn) => btn.addEventListener('click', () => this._accept()));

    const declineBtn = this._node.querySelectorAll('[data-invite-decline]');
    declineBtn.forEach((btn) => btn.addEventListener('click', () => this._decline()));

    const closeBtn = this._node.querySelectorAll('[data-invite-close]');
    closeBtn.forEach((btn) => btn.addEventListener('click', () => this.close(true)));
  }

  _show() {
    HtmlUtils.showNode(this._node);
    this._showInviteEvent.trigger();
  }

  _accept() {
    if (!this._node.hasAttribute('data-invite-persistent')) {
      this.close();
    }
    this._acceptInviteEvent.trigger();
  }

  _decline() {
    this.close();
    this._declineInviteEvent.trigger();
  }
}
