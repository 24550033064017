import runProgram from './app/run-program';
import TestModeWarning from './app/test-mode';
import GlobalInstance from './global-instance';
import {version} from './server-constants';

const run = async (cfg) => {
  const {programUrl, isTest, plugins = []} = cfg;

  if (isTest) {
    new TestModeWarning().render();
  }

  await runProgram(programUrl, plugins);
};

GlobalInstance[version] = {
  run,
};
