export default class SurveyConfig {
  constructor({id, url}) {
    this._id = id;
    this._url = url;
  }

  get id() {
    return this._id;
  }
  get url() {
    return this._url;
  }
}
