import Api from './api';
import wrapEvent from './wrap-event';

const createPublicApi = (apiConfig, programConfig, scenarioConfig, pluginsConfig) => {
  const instance = new Api(apiConfig, programConfig, scenarioConfig, pluginsConfig);

  const publicApi = {
    invite: (name, options) => {
      instance.setInvite(name, options);
      return publicApi;
    },
    container: (name, options) => {
      instance.setContainer(name, options);
      return publicApi;
    },
    survey: (surveyId) => {
      instance.setSurvey(surveyId);
      return publicApi;
    },
    data: (data, shouldMerge = false) => {
      instance.setSurveyData(data, shouldMerge);
      return publicApi;
    },
    show: () => {
      instance.show();
      return publicApi;
    },
    loadCountersAsync: async () => {
      await instance.loadCountersAsync();
      return publicApi;
    },
    scenarioCounters: (surveyId) => instance.getScenarioCounters(surveyId),
    checkContactFrequencyRules: (projectId, contactId) => instance.checkContactFrequencyRules(projectId, contactId),
    usePlugin: (pluginName) => {
      instance.usePlugin(pluginName, publicApi);
      return publicApi;
    },

    events: {
      showInvite: wrapEvent(instance.events.showInvite),
      acceptInvite: wrapEvent(instance.events.acceptInvite),
      declineInvite: wrapEvent(instance.events.declineInvite),
      closeInvite: wrapEvent(instance.events.closeInvite),

      showContainer: wrapEvent(instance.events.showContainer),
      completeSurvey: wrapEvent(instance.events.completeSurvey),
      closeContainer: wrapEvent(instance.events.closeContainer),
    },
  };

  return publicApi;
};

const runScenario = async (program, scenario, scenarioScriptPromise, plugins) => {
  const publicApiFn = (apiConfig = {}) => createPublicApi(apiConfig, program, scenario, plugins);
  const scenarioFn = await scenarioScriptPromise;

  scenarioFn(publicApiFn);
};

export default runScenario;
