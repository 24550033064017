export default class ScenarioConfig {
  constructor({id, link: {url, integrity}}) {
    this._id = id;
    this._url = url;
    this._integrity = integrity;
  }

  get id() {
    return this._id;
  }
  get url() {
    return this._url;
  }
  get integrity() {
    return this._integrity;
  }
}
