import Event from './event';
import * as HtmlUtils from './html-utils';
import loadResource from './load-resource';

export default class ContainerView {
  constructor(container, surveyUrl, options = {}) {
    this._container = container;
    this._surveyUrl = surveyUrl;
    this._options = options;

    this._node = null;
    this._frame = null;
    this._autoHeight = false;

    this._ignoreFocusChanges = false;

    this._showContainerEvent = new Event('container:show');
    this._completeSurveyEvent = new Event('survey:complete');
    this._closeContainerEvent = new Event('container:close');
    this._surveyMessageEvent = new Event('container:surveymessage');
  }

  get showContainerEvent() {
    return this._showContainerEvent;
  }

  get completeSurveyEvent() {
    return this._completeSurveyEvent;
  }

  get closeContainerEvent() {
    return this._closeContainerEvent;
  }

  get surveyMessageEvent() {
    return this._surveyMessageEvent;
  }

  async render() {
    this.close();

    const {url: containerUrl} = this._container;
    const {
      data: {html, css},
    } = await loadResource(containerUrl);

    this._renderContents(html, css);
    this._attachEvents();
    this._setSurveyUrl();
    this._show();
  }

  close(fireEvent = false) {
    if (this._node) {
      HtmlUtils.removeNode(this._node);

      this._node = null;
      if (fireEvent) {
        this._closeContainerEvent.trigger();
      }
    }
  }

  setHidden() {
    this._node && HtmlUtils.hideNode(this._node);
  }

  setVisible() {
    this._node && HtmlUtils.showNode(this._node);
  }

  _resolveTarget() {
    const {target} = this._options;

    if (!target) return document.body;

    if (typeof target === 'string') return document.querySelector(target);

    return target;
  }

  _renderContents(html, css) {
    const target = this._resolveTarget();
    this._node = HtmlUtils.appendNode(html, target);

    const styles = `<style>${css}</style>`;
    HtmlUtils.appendNode(styles, this._node);

    this._frame = this._node.querySelector('[data-container-frame]');
    this._adjustForAutoHeight();
  }

  _adjustForAutoHeight() {
    const isAutoHeight = this._node.hasAttribute('data-auto-height');
    const isMobile = this._isMobile();

    if (isAutoHeight && !isMobile) {
      this._autoHeight = true;
      this._frame.setAttribute('scrolling', 'no');
    } else {
      this._autoHeight = false;
      this._node.removeAttribute('data-auto-height');
    }
  }

  _isMobile() {
    const mobileThreshold = +this._node.getAttribute('data-mobile-threshold') || 640;
    const screenWidth = window.innerWidth || document.documentElement.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight;
    return Math.min(screenWidth, screenHeight) <= mobileThreshold;
  }

  _onForwardFocusTrap(e) {
    if (this._ignoreFocusChanges) return;

    this._ignoreFocusChanges = true;
    e.preventDefault();
    const container = this._node.querySelector('[data-container]');
    if (container) {
      const activeElement = HtmlUtils.getFirstFocusableDescendant(container);
      if (activeElement && activeElement.nodeName === 'IFRAME') {
        this.sendMessageToSurvey({type: 'cf-focus-first-element'});
      }
    }
    this._ignoreFocusChanges = false;
  }

  _onBackwardFocusTrap(e) {
    if (this._ignoreFocusChanges) return;

    this._ignoreFocusChanges = true;
    e.preventDefault();
    const container = this._node.querySelector('[data-container]');
    if (container) {
      const activeElement = HtmlUtils.getLastFocusableDescendant(container);
      if (activeElement && activeElement.nodeName === 'IFRAME') {
        this.sendMessageToSurvey({type: 'cf-focus-last-element'});
      }
    }
    this._ignoreFocusChanges = false;
  }

  _onEscapeKey(e) {
    if (e.key === 'Escape' || e.key === 'Esc') {
      this.close(true);
      e.stopPropagation();
    }
  }

  _attachEvents() {
    if (this._frame) {
      this._frame.addEventListener('load', () => this._surveyLoaded());
      window.addEventListener('message', (event) => this._handleMessage(event));
    }

    const btnClose = this._node.querySelectorAll('[data-container-close]');
    btnClose.forEach((el) => el.addEventListener('click', () => this.close(true)));

    const containerShim = this._node.querySelector('[data-container-shim]');
    if (containerShim) {
      containerShim.addEventListener('keyup', (event) => this._onEscapeKey(event));
    }

    const backwardFocusTrap = this._node.querySelector('[data-focus-trap-backward]');
    if (backwardFocusTrap) {
      backwardFocusTrap.addEventListener('focus', (event) => this._onBackwardFocusTrap(event));
    }

    const forwardFocusTrap = this._node.querySelector('[data-focus-trap-forward]');
    if (forwardFocusTrap) {
      forwardFocusTrap.addEventListener('focus', (event) => this._onForwardFocusTrap(event));
    }
  }

  _show() {
    HtmlUtils.showNode(this._node);
    this._showContainerEvent.trigger();

    if (this._frame) {
      this._frame.focus();
    }
  }

  _surveyLoaded() {
    if (this._node) {
      const loader = this._node.querySelectorAll('[data-container-loader]');
      loader.forEach((el) => HtmlUtils.hideNode(el));
      if (this._frame) {
        this._frame.focus();
        this.sendMessageToSurvey({type: 'cf-focus-first-element'});
      }
    }
  }

  _handleMessage(event) {
    if (event.source !== this._frame.contentWindow || typeof event.data !== 'object') return;

    switch (event.data.type) {
      case 'cf-survey-end':
        this._onSurveyEnd();
        break;
      case 'cf-survey-break':
        this._onSurveyBreak();
        break;
      case 'cf-height-change':
        this._onHeightChange(event.data.value);
        break;
    }
    this._surveyMessageEvent.trigger(event.data);
  }

  sendMessageToSurvey(data) {
    if (this._frame) {
      this._frame.contentWindow.postMessage(data, this._frame.src);
    }
  }

  _onSurveyEnd() {
    this._completeSurveyEvent.trigger();
    this.close();
  }

  _onSurveyBreak() {
    this.close(true);
  }

  _onHeightChange(newHeight) {
    if (!this._autoHeight || !this._frame) {
      return;
    }

    this._frame.style.height = newHeight + 'px';
  }

  _setSurveyUrl() {
    if (this._frame && this._surveyUrl) this._frame.src = this._surveyUrl;
  }
}
