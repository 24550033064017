import * as HtmlUtils from './html-utils';

const WARNING_HTML = `
<div class="cf-tm-warning-container">
    <style>
    .cf-tm-warning { pointer-events: none; box-sizing: border-box; position: fixed; top: 0; left: 0; width: 100%; opacity: 1; transition: top 0.5s, opacity 0.5s; z-index: 9999; }
    .cf-tm-warning * { box-sizing: inherit !important; }
    .cf-tm-warning__wrapper { pointer-events: auto; max-width: 600px; min-width:300px; min-height: 30px; margin: 0 auto; display: flex; justify-content: space-between; align-items: center; padding: 8px 8px; border-radius: 0 0 5px 5px; box-shadow: 0 0 16px #000; background-color: #ff8000; color: #ffffff;}
    .cf-tm-warning__content { flex: 1 1 auto; white-space: nowrap; display:flex; min-width:200px; padding-left: 46px; font-family: Arial; font-size:12px; font-weight: bold; height: 30px; line-height: 30px; background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30' %3E%3Ccircle r='12' cx='15' cy='15' fill='%23fff'/%3E%3Ctext x='50%25' y='50%25' text-anchor='middle' stroke='%23ff8000' stroke-width='1.5px' dy='0.3em'%3Ei%3C/text%3E%3C/svg%3E"); background-repeat: no-repeat; background-position:8px center; }
    .cf-tm-warning__text { flex: 0 1 auto; overflow: hidden; text-overflow: ellipsis; }
    .cf-tm-warning__exit { margin-left: 12px; border-radius: 2px; min-width: 60px; border: none; background-color: #ffce9c; color: #ff8000; font-weight: bold; cursor: pointer;  }
    .cf-tm-warning__close { flex: 0 0 auto; cursor: pointer; min-width: 30px; min-height: 30px; background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3E%3Cline x1='10' y1='10' x2='20' y2='20' stroke-width='2.5' stroke='%23ffce9c'/%3E%3Cline x1='10' y1='20' x2='20' y2='10' stroke-width='2.5' stroke='%23ffce9c'/%3E%3C/svg%3E"); background-repeat: no-repeat; background-position: center; background-size: 100%, 100%; }
    .cf-tm-warning--hidden { top: -48px; opacity: 0; }
    </style>
    
    <div class="cf-tm-warning cf-tm-warning--hidden">
        <div class="cf-tm-warning__wrapper">
            <div class="cf-tm-warning__content">
                <span class="cf-tm-warning__text">Digital Feedback is running in Test Mode.</span>
                <!--<button class="cf-tm-warning__exit">Exit</button>-->
            </div>
            <div class="cf-tm-warning__close"></div>
        </div>
    </div>
</div>
`;

export default class TestModeWarning {
  constructor() {
    this._container = null;
  }

  render() {
    this._renderContents();
    this._show();
  }

  _renderContents() {
    this._container =
      document.body.querySelector('.cf-tm-warning-container') ||
      HtmlUtils.appendNode(WARNING_HTML, document.body, false);

    const closeBtn = this._container.querySelector('.cf-tm-warning__close');
    if (closeBtn) closeBtn.addEventListener('click', () => this._hide());
  }

  _show() {
    const warning = this._container.querySelector('.cf-tm-warning');
    warning.offsetTop; // cause side effect to make sure initial transition is applied
    warning.classList.remove('cf-tm-warning--hidden');
  }

  _hide() {
    const warning = this._container.querySelector('.cf-tm-warning');
    warning.classList.add('cf-tm-warning--hidden');
  }
}
